// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAAnTmzsHW3Z_LP4uNpFhTYFQFeqbg1hVM",
  authDomain: "foodbook-8a561.firebaseapp.com",
  projectId: "foodbook-8a561",
  storageBucket: "foodbook-8a561.appspot.com",
  messagingSenderId: "756806423363",
  appId: "1:756806423363:web:abe8a533480569789695f6",
  measurementId: "G-Y76ZTRMSCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
export {db, storage}
